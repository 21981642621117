import { Grid, Row, Col } from '@freecodecamp/react-bootstrap';
import './about.css'
import React from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
// import Banner from '../components/landing/components/banner';
// import Feature from '../components/landing/components/feature';
// import Process from '../components/landing/components/process';
// import Partner from '../components/landing/components/partner';
// import Try from '../components/landing/components/try';
// import Certifications from '../components/landing/components/certifications';
import Header from '../components/landing/components/header';
import Footer from '../components/landing/components/footer';

function TermsPage() {

  return (
    <>
      <Helmet>
        <title>Extraise</title>
        <link rel="shortcut icon" href="/assets/images/logo/favourite_icon_1.png" />
        <link rel="stylesheet" type="text/css" href="/assets/css/bootstrap.min.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/icomoon.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/themify-icons.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/fontawesome-all.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/owl.carousel.min.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/owl.theme.default.min.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/aos.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/animate.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/splitting.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/magnific-popup.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/style.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/_extraise.css" />
        <script defer key="jquery" src="/assets/js/jquery-3.4.1.min.js" />
        <script defer key="popper" src="/assets/js/popper.min.js" />
        <script defer key="bootstrap" src="/assets/js/bootstrap.min.js" />
        <script defer key="owl-carousel" src="/assets/js/owl.carousel.min.js" />
        <script defer key="aos" src="/assets/js/aos.js" />
        <script defer key="splitting" src="/assets/js/splitting.js" />
        <script defer key="magnific-popup" src="/assets/js/magnific-popup.min.js" />
        <script defer key="isotope" src="/assets/js/isotope.pkgd.min.js" />
        <script defer key="masonry" src="/assets/js/masonry.pkgd.min.js" />
        <script defer key="imagesloaded" src="/assets/js/imagesloaded.pkgd.min.js" />
        <script defer key="parallax" src="/assets/js/parallax.min.js" />
        <script defer key="parallax-scroll" src="/assets/js/parallax-scroll.js" />
        <script defer key="gmaps" src="/assets/js/gmaps.js" />
        <script defer key="mCustomScrollbar" src="/assets/js/mCustomScrollbar.js" />
        <script defer key="custom" src="/assets/js/custom.js" />
      </Helmet>
      <Header />

      {/* <section id="breadcrumb_section" className="breadcrumb_section bg_gradient_blue deco_wrap d-flex align-items-center text-white clearfix" style={{ minHeight: "auto", padding: "55px 0" }}>
        <div className="container">
          <div className="breadcrumb_content text-center" data-aos-delay={100}>
            <h1 className="page_title" style={{ fontSize: "25px" }}>Pricing Plans</h1>
            <p className="mb-0">
              Stay ahead of the curve with
              Extraise Pro.
            </p>
          </div>
        </div>
        <div className="deco_image spahe_1" data-aos="fade-down" data-aos-delay={300}>
          <img src="/assets/images/shapes/shape_1.png" alt="spahe_not_found" />
        </div>
        <div className="deco_image spahe_2" data-aos-delay={400}>
          <img src="/assets/images/shapes/shape_2.png" alt="spahe_not_found" />
        </div>
      </section> */}
      <main>
        <section id="banner_section" className="banner_section software_banner deco_wrap d-flex align-items-center text-white clearfix" style={{ minHeight: "400px", backgroundColor: "#fbfbfd", paddingBottom: "220px", paddingTop: "200px" }} >
          <div className="container">
            <div className="row align-items-center justify-content-lg-between">

              <div className="col-lg-7">
                <div className="banner_content c_slide_in">
                  <h1 className="title_text mb-30 text_effect_wrap text_effect_wrap">
                    {/* <span className="text_effect text_effect_left">
                      About us
                    </span><br /> */}
                    <span className="text_effect text_effect_left">
                      Terms of Service
                    </span>
                  </h1>
                  <p className="mb-50" data-aos="fade-up" data-aos-delay={200}>
                    { } <br /><small>{ }</small>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="deco_image bg_shape_1" data-background="/assets/images/banner/10_software/bg_1.png" />
          <div className="deco_image bg_shape_2" data-background="/assets/images/banner/10_software/bg_2.png" style={{ backgroundSize: "cover" }} />
          <div className="deco_image bg_shape_3" data-background="/assets/images/banner/10_software/bg_3.png" data-aos="zoom-out" data-aos-delay={100} />
          <div className="deco_image shape_1" data-aos="fade-left" data-aos-delay={500}>
            <img src="/assets/images/banner/10_software/shape_1.png" alt="shape_not_found" />
          </div>
          <div className="deco_image shape_2" data-aos="fade-right" data-aos-delay={700}>
            <img src="/assets/images/banner/10_software/shape_2.png" alt="shape_not_found" />
          </div>
          <div className="deco_image cloud_1" data-aos="fade-up" data-aos-delay={500}>
            <img src="/assets/images/banner/10_software/cloud_1.png" alt="shape_not_found" />
          </div>
          <div className="deco_image cloud_2" data-aos="fade-up" data-aos-delay={700}>
            <img src="/assets/images/banner/10_software/cloud_1.png" alt="shape_not_found" />
          </div>
        </section>

        <section className="about-section banner_section software_banner d-flex align-items-center clearfix" style={{ minHeight: "400px", backgroundColor: "#fbfbfd" }}>
        <div className="container">
          <div className="row">
            <div className="content-column col-md-12 col-sm-12 order-2">
              <div className="inner-column">
                <div className="sec-title">
                  <h2>Extraise Platform - Terms of Service</h2>
                </div>
                <div className="text">
                Welcome to Extraise, a platform dedicated to providing high-quality online frontend development training. Before you begin using our services, please carefully read and understand the following Terms of Service. By accessing or using the Extraise platform, you agree to comply with and be bound by these terms.
                </div>
              </div>
            </div>
          </div>
          <div className="column">
            <div className="sec-title">
              <h2>1. Acceptance of Terms</h2>
            </div>
            <div className="text">
            By accessing or using the Extraise platform, you acknowledge that you have read, understood, and agree to be bound by these Terms of Service. If you do not agree with any part of these terms, you may not use our services.
            </div>
          </div>
          <div className="column">
            <div className="sec-title">
              <h2>2. User Eligibility</h2>
            </div>
            <div className="text">
            You must be at least 12 years old or have the legal capacity to enter into a contract to use Extraise. By accessing or using our platform, you affirm that you meet these eligibility requirements.
            </div>
          </div>
          <div className="column">
            <div className="sec-title">
              <h2>3. Account Registration</h2>
            </div>
            <div className="text">
            To access certain features of Extraise, you may be required to create an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.
            </div>
          </div>
          <div className="column">
            <div className="sec-title">
              <h2>4. Content and Materials</h2>
            </div>
            <div className="text">
            All content and materials provided on Extraise, including but not limited to text, images, videos, and interactive exercises, are the intellectual property of Extraise or its licensors. You agree not to reproduce, distribute, modify, display, or create derivative works of any content without explicit permission from Extraise.
            </div>
          </div>
          <div className="column">
            <div className="sec-title">
              <h2>5. Payment and Refunds</h2>
            </div>
            <div className="text">
            Access to Extraise may require payment of fees. Payment details and refund policies are specified on our platform. By providing payment information, you represent and warrant that you are authorized to make the payment.
            </div>
          </div>
          <div className="column">
            <div className="sec-title">
              <h2>6. Code of Conduct</h2>
            </div>
            <div className="text">
            You agree to use Extraise in a manner consistent with all applicable laws and regulations and in accordance with our Code of Conduct. You shall not engage in any conduct that is harmful, offensive, or violates the rights of others.
            </div>
          </div>
          <div className="column">
            <div className="sec-title">
              <h2>7. Termination</h2>
            </div>
            <div className="text">
            Extraise reserves the right to terminate or suspend your account and access to our services at any time and for any reason, without prior notice. You may terminate your account by following the instructions on the platform.
            </div>
          </div>
          <div className="column">
            <div className="sec-title">
              <h2>8. Disclaimers</h2>
            </div>
            <div className="text">
            Extraise provides its services on an "as-is" and "as-available" basis. We make no warranties, express or implied, regarding the accuracy, completeness, or suitability of the content and materials provided.
            </div>
          </div>
          <div className="column">
            <div className="sec-title">
              <h2>9. Limitation of Liability</h2>
            </div>
            <div className="text">
            To the fullest extent permitted by law, Extraise and its affiliates shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly.
            </div>
          </div>
          <div className="column">
            <div className="sec-title">
              <h2>10. Governing Law</h2>
            </div>
            <div className="text">
            These Terms of Service shall be governed by and construed in accordance with the laws of Government of India, without regard to its conflict of law principles.            </div>
          </div>
          <div className="column">
            <div className="sec-title">
              <h2>11. Changes to Terms</h2>
            </div>
            <div className="text">
            Extraise reserves the right to update or modify these Terms of Service at any time. You are responsible for regularly reviewing the terms. Your continued use of the platform after any changes constitutes acceptance of those changes.
            </div>
          </div>
          <div className="column">
            <div className="text">
            If you have any questions or concerns regarding these terms, please contact us at info@olecons.com.
            </div>
            <div className="text">Thank you for choosing Extraise for your online frontend development training needs!</div>
          </div>
        </div>
      </section>
        {/* <Feature /> */}
        {/* <Process /> */}
        {/* <Partner /> */}
        {/* <Try /> */}

      </main>
      <Footer />
    </>
  );
}

TermsPage.displayName = 'terms';

export default connect(null)(TermsPage);

